import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { Page, Template } from '@distribute/shared/types';
import { CustomTaskList } from '../../../../../src/features/tasks/ui';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../../../src/features/pages';
import { templatesModel } from '../../../../../src/features/templates';
import { tasksModel } from '../../../../../src/features/tasks';
import { useCallback, useEffect } from 'react';
import { captureMessage, Severity } from '@sentry/react';

export type CustomTaskListNodeType = NodeViewProps['node'];

export type CustomTaskListNodeViewProps = NodeViewProps & {
  node: CustomTaskListNodeType;
};

export const CustomTaskListNodeView = ({
  node,
  getPos,
  editor,
  updateAttributes,
}: CustomTaskListNodeViewProps) => {
  const dispatch = useDispatch();
  const pos = getPos();

  const page: Page | undefined = useSelector(
    pagesModel.selectors.selectCurrentPage
  );

  const template: Template | undefined = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const documentContentId = page?.content.id || template?.content.id;

  const tasklist = useSelector(tasksModel.selectors.selectTasklists)[
    node.attrs.id
  ];

  const insertNewTasklist = useCallback(async () => {
    if (!documentContentId) {
      return;
    }

    dispatch(
      tasksModel.actions.createTasklist({
        documentContentId,
        successCallback: (id: string) => {
          editor
            .chain()
            .insertContentAt(pos + node.nodeSize, {
              type: 'customTaskList',
              attrs: {
                id,
              },
            })
            .run();

          setTimeout(() => {
            const currentTasklist = document.querySelector(
              `[data-id="${id}"]`
            ) as HTMLElement;

            if (currentTasklist) {
              const textarea = currentTasklist.querySelector('textarea');

              if (textarea) {
                textarea.focus();
              }
            }
          }, 0);
        },
      })
    );
  }, [documentContentId, editor, dispatch, node.nodeSize, pos]);

  useEffect(() => {
    if (node.attrs.id && !tasklist) {
      dispatch(
        tasksModel.actions.updateTasklistDataFromDb({
          tasklistId: node.attrs.id,
          successCallback: () => {
            return;
          },
        })
      );
    }
  }, [node.attrs.id, dispatch, tasklist]);

  useEffect(() => {
    if (!node.attrs.id) {
      captureMessage('Tasklist: id is not found', {
        level: Severity.Error,
        extra: {
          documentContentId,
        },
      });
    }
  }, [node.attrs.id, documentContentId]);

  return (
    <NodeViewWrapper className="!border-none" contentEditable={false}>
      {tasklist && documentContentId ? (
        <CustomTaskList
          id={node.attrs.id}
          insertNewTasklist={insertNewTasklist}
          updateAttributes={updateAttributes}
          updatedAt={node.attrs.updatedAt}
          updatedBy={node.attrs.updatedBy}
          documentContentId={documentContentId}
        />
      ) : (
        <div>_tasklist is not found_</div>
      )}
    </NodeViewWrapper>
  );
};
