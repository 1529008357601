import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { pagesModel, usePagePermissions } from '../../../features/pages';
import { EditorWrapper, PreviewPage, TiptapEditorPage } from './components';
import { useWindowWidth } from '@distribute/frontend/utils';
import { Page, TemplateCreationPhase } from '@distribute/shared/types';
import { useSetDocumentContentStyles } from '../../../shared/hooks/useSetDocumentContentStyles';
import debounce from 'debounce';
import {
  TiptapEditorProvider,
  TiptapEditorProviderProps,
} from '../../../entities/tiptap-editor';
import { templatesModel } from '../../../features/templates';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

import { DropHandleExtension } from '../../../entities/tiptap-editor/extensions/DropHandle/DropHandleExtension';

import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';
import { EditorEvents, JSONContent } from '@tiptap/react';
import { getMainEditorExtensions } from '../../../entities/tiptap-editor/mainEditorExtensions';
import { AIExtension } from '../../../entities/tiptap-editor/extensions/AI';
import TriggerMenu from '../../../entities/tiptap-editor/extensions/TriggerMenuExtension';
import { SLASH_MENU_GROUPS } from '../lib/commands';
import { EditorPreviewModeEnum } from '../../../features/pages/model/types';
import { useSubscriptionLimits } from '../../../features/subscription/hooks';
import type { DocumentContentItem } from '@distribute/shared/types';
import { JSONContentFactory } from '@distribute/shared/utils';
import { useCollaboration } from '../../../entities/collaboration';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { authUserModel } from '../../../entities/auth-user';
import {
  getUserCollaborationColor,
  renderCollaborativeCursor,
} from '@distribute/shared/yjs';
import { logger } from '../../../shared/lib';

type Props = {
  isTemplateMode: boolean;
};

export const Editor: React.FC<Props> = ({ isTemplateMode }) => {
  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();
  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );
  const dispatch = useDispatch();
  const store = useStore();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPage
  ) as Page;

  const currentPageContentId = useSelector(
    pagesModel.selectors.selectCurrentContentId
  ) as number;

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  ) as TemplateExtended;

  const currentTemplateContentId = useSelector(
    templatesModel.selectors.selectCurrentTemplateContentId
  ) as number;

  const {
    isCollaborationEnabled,
    provider,
    tabs: collaborativeTabs,
    title,
  } = useCollaboration();

  const currentPageOrTemplate = isTemplateMode ? currentTemplate : currentPage;
  const currentContentId = isTemplateMode
    ? currentTemplateContentId
    : currentPageContentId;

  const content = currentPageOrTemplate?.content;
  const currentContent =
    isTemplateMode || !isCollaborationEnabled
      ? (content?.contentItems.find(
          ({ id }) => id === currentContentId
        ) as DocumentContentItem)
      : (collaborativeTabs.find(
          (tab) => tab.id === currentContentId
        ) as DocumentContentItem);

  const {
    isCanEditDocumentContent,
    isCanEditPageStyles,
    isCanEditConversionTools,
    isCanEditPageSettings,
  } = usePagePermissions(currentPage);

  const isCanEditPage =
    isCanEditConversionTools &&
    isCanEditPageStyles &&
    isCanEditDocumentContent &&
    isCanEditPageSettings;
  const isCanEdit = isTemplateMode ? currentTemplate.isOwner : isCanEditPage;
  const showPreview = isEditorPreview || isMobile || !isCanEdit || isTablet;

  const isSupportMultiTabs = isTemplateMode
    ? !currentTemplate.isSinglePage
    : true;

  useSetDocumentContentStyles(content ?? {});

  useEffect(() => {
    return () => {
      dispatch(
        pagesModel.actions.setEditorPreviewMode(EditorPreviewModeEnum.DESKTOP)
      );
    };
  }, [currentPageOrTemplate?.id, dispatch]);

  const data = currentContent?.contentJson || JSONContentFactory.DEFAULT();

  const tabs = [...(currentPageOrTemplate?.content.contentItems ?? [])].sort(
    (a, b) => a.order - b.order
  );

  const handleChange = debounce((contentJson: JSONContent) => {
    if (isTemplateMode) {
      const updatedContentItems = currentTemplate.content.contentItems.map(
        (item) => {
          if (item.id === currentContentId) {
            return { ...item, contentJson };
          }
          return item;
        }
      );

      dispatch(
        templatesModel.actions.setCurrentTemplate({
          ...currentTemplate,
          content: {
            ...currentTemplate.content,
            contentItems: updatedContentItems,
          },
        })
      );

      if (currentTemplate.creationPhase === TemplateCreationPhase.DRAFT) {
        dispatch(templatesModel.actions.updateTemplateFromEditor());
      }

      dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      dispatch(
        pagesModel.actions.changePageContent({
          contentItemId: currentContentId,
          pageId: currentPage.id,
          contentJson,
        })
      );
    }
  }, 400);

  const handleTemplateChange = (contentJson: JSONContent) => {
    if (currentTemplate.creationPhase === TemplateCreationPhase.DRAFT) {
      dispatch(templatesModel.actions.setTemplateUpdating(true));
    }
    handleChange(contentJson);
  };

  const { snippets: allowSnippets } = useSubscriptionLimits();

  const user = useSelector(authUserModel.selectors.selectUserWithError);

  const handleContentError = ({
    editor,
    error,
    disableCollaboration,
  }: EditorEvents['contentError']) => {
    disableCollaboration();
    logger.error('Invalid schema error: ', error);

    editor.setEditable(false, false);

    dispatch(pagesModel.actions.setIsEditorContentError(true));
  };

  const providerCommonProps = {
    tabId: currentContentId,
    options: {
      editorProps: {
        attributes: {
          class: 'main-editor',
        },
      },
      extensions: [
        ...getMainEditorExtensions(!isTemplateMode && isCollaborationEnabled),
        DropHandleExtension.configure({
          dispatch,
        }),
        AIExtension,
        TriggerMenu.configure({
          store,
          trigger: '/',
          groups: SLASH_MENU_GROUPS,
        }),
      ],
    },
    children: undefined, // to be inserted later
  } as TiptapEditorProviderProps;

  const getTiptapTemplateProviderProps = () =>
    ({
      ...providerCommonProps,
      options: {
        ...providerCommonProps.options,
        content: data,
        onUpdate: ({ editor, transaction }) => {
          if (transaction.getMeta('isPreventUpdate')) return;
          handleTemplateChange(editor.getJSON());
        },
      },
      canUseCollaboration: false,
    } as TiptapEditorProviderProps);

  const getTiptapBasicProviderProps = () =>
    ({
      preventClearHistory: true,
      ...providerCommonProps,
      options: {
        ...providerCommonProps.options,
        content: data,
        onUpdate: ({ editor, transaction }) => {
          if (transaction.getMeta('isPreventUpdate')) return;
          handleChange(editor.getJSON());
        },
      },
    } as TiptapEditorProviderProps);

  const getTiptapCollaborativeProviderProps = () =>
    ({
      ...providerCommonProps,
      provider,
      options: {
        ...providerCommonProps.options,
        enableContentCheck: true,
        onContentError: handleContentError,
        extensions: [
          ...(providerCommonProps.options?.extensions || []),
          Collaboration.configure({
            fragment: provider?.doc.getXmlFragment(
              currentPageContentId.toString()
            ),
          }),
          CollaborationCursor.configure({
            provider,
            user: {
              id: user.id,
              name: user.displayName,
              email: user.email,
              color: getUserCollaborationColor(),
              photoUrl: user.photoUrl,
              activeTabId: currentContentId,
            },
            render: renderCollaborativeCursor,
          }),
        ],
      },
    } as TiptapEditorProviderProps);

  const tiptapProviderProps = isTemplateMode
    ? getTiptapTemplateProviderProps()
    : isCollaborationEnabled
    ? getTiptapCollaborativeProviderProps()
    : getTiptapBasicProviderProps();

  const editorTabs =
    isCollaborationEnabled && !isTemplateMode
      ? (collaborativeTabs as DocumentContentItem[])
      : tabs;

  return (
    <TiptapEditorProvider {...tiptapProviderProps}>
      <EditorWrapper
        isPreviewMode={showPreview}
        currentPageOrTemplate={currentPageOrTemplate}
        isTemplateMode={isTemplateMode}
        isCanEdit={isCanEdit}
        isSupportMultiTabs={isSupportMultiTabs}
      >
        {showPreview && !isTemplateMode ? (
          <PreviewPage
            pageId={currentPage.id}
            title={isCollaborationEnabled ? title : currentPage.content.title}
          />
        ) : (
          <TiptapEditorPage
            isSupportMultiTabs={isSupportMultiTabs}
            currentTab={currentContent}
            tabs={editorTabs}
            currentPageOrTemplate={currentPageOrTemplate}
            isTemplateMode={isTemplateMode}
          />
        )}
      </EditorWrapper>
    </TiptapEditorProvider>
  );
};
