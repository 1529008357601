import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddTaskAction,
  CreateTasklistAction,
  DeleteTaskAction,
  DuplicateTaskAction,
  RemoveTaskAssigneeAction,
  ReorderTasksAction,
  SetTaskAssigneeAction,
  UpdateTaskAction,
  UpdateTasklistAction,
  updateTasklistDataFromDbAction,
} from './types';
import { globalActions } from '../../../../src/app/model/actions';
import { TasklistWithTasks } from '@distribute/shared/types';

type State = {
  tasklists: Record<string, TasklistWithTasks>;
};

const initialState: State = {
  tasklists: {},
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasklists: (
      state,
      { payload: tasklists }: PayloadAction<Record<string, TasklistWithTasks>>
    ) => ({
      ...state,
      tasklists,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  updateTask: createAction<UpdateTaskAction>('tasks/updateTask'),
  setTaskAssignee: createAction<SetTaskAssigneeAction>('tasks/setTaskAssignee'),
  removeTaskAssignee: createAction<RemoveTaskAssigneeAction>(
    'tasks/removeTaskAssignee'
  ),
  duplicateTask: createAction<DuplicateTaskAction>('tasks/duplicateTask'),
  deleteTask: createAction<DeleteTaskAction>('tasks/deleteTask'),
  updateTasklist: createAction<UpdateTasklistAction>('tasks/updateTasklist'),
  updateTasklistDataFromDb: createAction<updateTasklistDataFromDbAction>(
    'tasks/updateTasklistDataFromDb'
  ),
  reorderTasks: createAction<ReorderTasksAction>('tasks/reorderTasks'),
  addTask: createAction<AddTaskAction>('tasks/addTask'),
  createTasklist: createAction<CreateTasklistAction>('tasks/createTasklist'),
};
