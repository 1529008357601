import React, { useEffect, useRef } from 'react';
import { MenuList } from './MenuList';
import { useTiptapEditor } from '../../lib';
import { SLASH_MENU_GROUPS } from '../../../../pages/editor/lib/commands';
import { Command } from '../types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { pagesModel } from '../../../../features/pages';
import cn from 'classnames';
import { useOnClickOutside } from '../../../../shared/hooks/useClickOutside';
import { useSubscriptionLimits } from '../../../../features/subscription/hooks';

type Props = {
  isHideSwitcher?: boolean;
};

export const MenuListWrapper: React.FC<Props> = ({
  isHideSwitcher = false,
}) => {
  const { editor } = useTiptapEditor();
  const ref = useRef<HTMLDivElement | null>(null);
  const isOpen = useSelector(pagesModel.selectors.selectSlashMenuOpen);
  const slashMenuPositionTop = useSelector(
    pagesModel.selectors.selectSlashMenuPositionTop
  );
  const store = useStore();

  const slashMenuPositionLeft = useSelector(
    pagesModel.selectors.selectSlashMenuPositionLeft
  );

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.top = `${slashMenuPositionTop}px`;
    ref.current.style.left = `${slashMenuPositionLeft}px`;
  }, [slashMenuPositionLeft, slashMenuPositionTop]);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(pagesModel.actions.setSlashMenuOpen(false));
  };
  useOnClickOutside(ref, handleClose);

  const { snippets: allowSnippets } = useSubscriptionLimits();

  if (!editor) return null;

  return (
    <div
      className={cn('w-full h-full relative', {
        hidden: !isOpen,
        block: isOpen,
      })}
    >
      <div className="w-80 absolute z-30" ref={ref}>
        <MenuList
          editor={editor}
          items={SLASH_MENU_GROUPS}
          isHideSwitcher={isHideSwitcher || !allowSnippets}
          command={(command: Command) => {
            command.action(editor, store);
            dispatch(pagesModel.actions.setSlashMenuOpen(false));
          }}
        />
      </div>
    </div>
  );
};
