import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { JSONContent } from '@tiptap/core';
import {
  TiptapEditor,
  TiptapEditorProvider,
} from '../../../../src/entities/tiptap-editor';
import { AIExtension } from '../../../../src/entities/tiptap-editor/extensions/AI';
import { DropHandleExtension } from '../../../../src/entities/tiptap-editor/extensions/DropHandle/DropHandleExtension';
import { TableMenu } from '../../../../src/entities/tiptap-editor/extensions/Table';
import { getMainEditorExtensions } from '../../../../src/entities/tiptap-editor/mainEditorExtensions';
import { TiptapCalloutBubbleMenu } from '../../../../src/entities/tiptap-editor/ui/basic-editor/TiptapCalloutBubbleMenu';
import { TiptapTextBubbleMenu } from '../../../../src/entities/tiptap-editor/ui/minimal-editor/TiptapTextBubbleMenu';
import Placeholder from '@tiptap/extension-placeholder';

type Props = {
  notes: JSONContent;
  onNotesChange: (notes: JSONContent) => void;
};

export const TaskNotesEditor: FC<Props> = ({ notes, onNotesChange }) => {
  const dispatch = useDispatch();

  return (
    <TiptapEditorProvider
      options={{
        content: notes,
        editorProps: {
          attributes: {
            class: 'main-editor',
          },
        },
        extensions: [
          ...getMainEditorExtensions().filter(
            (ext) =>
              ext.name !== 'snippet' &&
              ext.name !== 'customTaskList' &&
              ext.name !== 'placeholder'
          ),
          DropHandleExtension.configure({
            dispatch,
          }),
          AIExtension,
          Placeholder.configure({
            placeholder: 'Type here...',
          }),
        ],
        onUpdate: ({ editor, transaction }) => {
          if (transaction.getMeta('isPreventUpdate')) return;
          onNotesChange(editor.getJSON());
        },
      }}
    >
      <TiptapTextBubbleMenu />
      <TableMenu />
      <TiptapCalloutBubbleMenu />
      <div className="">
        <TiptapEditor className="main-editor-wrapper" />
      </div>
    </TiptapEditorProvider>
  );
};
