import { Editor, EditorEvents, JSONContent } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import {
  LineActionMenuItem,
  LineActionMenuItemByType,
  ActionOptions,
} from './types';

import { Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { ColorSubMenu } from '../ui/ColorSubMenu';
import { IconSubMenu } from '../ui/IconSubMenu';
import { snippetsApi } from '../../../../../shared/api/axios/snippets';
import { MediaSnippetJSON, SnippetType } from '@distribute/shared/types';
import { YOUTUBE_URL_REGEX } from '../../../../../shared/constants';
import { DetachSnippetConfirmationModal } from '../../SnippetBlock/DetachSnippetConfirmationModal';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../../../../features/editor-sidebar';
import { Dispatch } from '@reduxjs/toolkit';

export const SHARED_ACTIONS = {
  duplicate: (editor: Editor, options: ActionOptions) => {
    const content = options.node.toJSON();

    if (options.node.type.name === 'column') {
      editor
        .chain()
        .focus(options.nodePosition)
        .duplicateOneColumn(options.node, options.nodePosition)
        .run();
      return;
    }

    editor
      .chain()
      .focus(options.nodePosition)
      .insertContentAt(options.nodePosition + options.node.nodeSize, content, {
        updateSelection: true,
      })
      .blur()
      .run();
  },
  delete: (editor: Editor, options: ActionOptions) => {
    const rangeToDelete = {
      from: options.nodePosition,
      to: options.nodePosition + options.node.nodeSize,
    };

    const nodesBetween: Array<{ node: Node; pos: number }> = [];

    editor.view.state.doc.nodesBetween(
      options.nodePosition,
      options.nodePosition,
      (node, pos) => {
        nodesBetween.push({ node, pos });
      }
    );

    for (let i = nodesBetween.length - 1; i >= 0; i--) {
      const { node, pos } = nodesBetween[i];
      if (node.isBlock && node.childCount <= 1) {
        rangeToDelete.from = pos;
        rangeToDelete.to = pos + node.nodeSize;
      } else {
        break;
      }
    }

    editor
      .chain()
      .focus(options.nodePosition)
      .deleteRange(rangeToDelete)
      .blur()
      .run();
  },
};

export const BASE_LINE_ACTION_MENU_GROUPS: LineActionMenuItem[] = [
  {
    name: 'Duplicate',
    key: 'duplicate',
    restrictedNodes: ['customTaskList'],
    icon: <Icon glyph={IconMap.Copy01} width={16} height={16} />,
    action: (editor: Editor, options: ActionOptions) => {
      SHARED_ACTIONS.duplicate(editor, options);
    },
  },
  {
    name: 'Delete',
    key: 'delete',
    icon: <Icon glyph={IconMap.Trash} width={16} height={16} />,
    action: (editor: Editor, options: ActionOptions) => {
      SHARED_ACTIONS.delete(editor, options);
    },
  },
];

export const LINE_ACTION_MENU_GROUPS: LineActionMenuItemByType = {
  callout: [
    {
      name: 'Icon',
      key: 'icon',
      icon: <Icon glyph={IconMap.FaceSmile} width={16} height={16} />,
      subItems: [
        {
          name: 'Icon',
          key: 'icon-update',
          component: IconSubMenu,
          action: (editor: Editor, options: ActionOptions) => {
            editor
              .chain()
              .focus()
              .updateAttributes('callout', {
                emoji: options.emoji,
              })
              .run();
          },
        },
      ],
    },
    {
      name: 'Background color',
      key: 'background-color',
      icon: <Icon glyph={IconMap.Paint} width={16} height={16} />,
      subItems: [
        {
          name: 'Background color',
          key: 'bg-update',
          component: ColorSubMenu,
          action: (editor: Editor, options: ActionOptions) => {
            editor
              .chain()
              .focus()
              .updateAttributes('callout', {
                bgColor: options.color,
              })
              .run();
          },
        },
      ],
    },
  ],
  snippet: [
    {
      name: 'Detach from snippet',
      key: 'detach',
      isSeparatedFromTop: true,
      icon: <Icon glyph={IconMap.LinkBroken} width={16} height={16} />,
      action: async (editor: Editor, options: ActionOptions) => {
        const pos = options.nodePosition;

        const deleteSnippetNode = () => {
          const rangeToDelete = {
            from: options.nodePosition,
            to: options.nodePosition + options.node.nodeSize,
          };

          const nodesBetween: Array<{ node: Node; pos: number }> = [];

          editor.view.state.doc.nodesBetween(
            options.nodePosition,
            options.nodePosition,
            (node, pos) => {
              nodesBetween.push({ node, pos });
            }
          );

          for (let i = nodesBetween.length - 1; i >= 0; i--) {
            const { node, pos } = nodesBetween[i];
            if (node.isBlock && node.childCount <= 1) {
              rangeToDelete.from = pos;
              rangeToDelete.to = pos + node.nodeSize;
            } else {
              break;
            }
          }

          editor
            .chain()
            .focus(options.nodePosition)
            .deleteRange(rangeToDelete)
            .blur()
            .run();
        };

        const detachSnippet = async () => {
          const currentSnippet = await snippetsApi.getSnippetData(
            options.node.attrs.id
          );

          if (currentSnippet?.type === SnippetType.TEXT) {
            const content = currentSnippet.content as JSONContent;

            content.content?.reverse().forEach((contentItem) =>
              editor
                .chain()
                .focus(pos)
                .insertContentAt(pos + options.node.nodeSize, {
                  ...contentItem,
                })
                .focus()
                .run()
            );

            deleteSnippetNode();
          }

          if (currentSnippet?.type === SnippetType.IMAGE) {
            const content = currentSnippet.content as MediaSnippetJSON;

            editor
              .chain()
              .focus(pos)
              .insertContentAt(pos + options.node.nodeSize, {
                type: 'resizeableFigure',
                content: [
                  {
                    type: 'image',
                    attrs: {
                      src: content.url,
                      alt: currentSnippet.name,
                      title: currentSnippet.name,
                    },
                  },
                ],
                attrs: options.node.attrs,
              })
              .blur()
              .run();

            deleteSnippetNode();
          }

          if (currentSnippet?.type === SnippetType.VIDEO) {
            const content = currentSnippet.content as MediaSnippetJSON;
            const youtubeMatch = content.url.match(YOUTUBE_URL_REGEX);

            editor
              .chain()
              .focus(pos)
              .insertContentAt(pos + options.node.nodeSize, {
                type: 'resizeableFigure',
                content: [
                  {
                    type: youtubeMatch ? 'youtube' : 'video',
                    attrs: {
                      src: content.url,
                    },
                  },
                ],
                attrs: options.node.attrs,
              })
              .blur()
              .run();

            deleteSnippetNode();
          }

          if (currentSnippet?.type === SnippetType.FILE) {
            const content = currentSnippet.content as MediaSnippetJSON;

            editor
              .chain()
              .focus(pos)
              .insertContentAt(pos + options.node.nodeSize, {
                type: 'resizeableFigure',
                content: [
                  {
                    type: 'iframe',
                    attrs: {
                      src: content.url,
                      alt: currentSnippet.name,
                      title: currentSnippet.name,
                    },
                  },
                ],
                attrs: options.node.attrs,
              })
              .blur()
              .run();

            deleteSnippetNode();
          }
        };

        const { destroyComponent } =
          editor.extensionManager.commands.renderReactComponentWithTippy(
            DetachSnippetConfirmationModal,
            {
              onClose: () => {
                destroyComponent();
              },
              onConfirm: () => {
                destroyComponent();
                detachSnippet();
              },
            }
          );
      },
    },
  ],
  contactCard: [
    {
      name: 'Edit content',
      key: 'edit-content',
      icon: <Icon glyph={IconMap.Edit05} width={16} height={16} />,
      action: (editor: Editor, options: ActionOptions, dispatch: Dispatch) => {
        dispatch(editorSidebarModel.actions.setSidebarOpen(true));
        dispatch(
          editorSidebarModel.actions.setActiveConversionSettingsPanel(
            ActiveConversionSettingsPanel.CONTACT_CARD_EDITOR
          )
        );

        const closeSidebarEditorOnBlur = ({
          editor: ed,
        }: EditorEvents['selectionUpdate']) => {
          if (!ed.isActive('contactCard')) {
            dispatch(editorSidebarModel.actions.setSidebarOpen(false));
            dispatch(
              editorSidebarModel.actions.setActiveConversionSettingsPanel(null)
            );
          }
          ed.off('selectionUpdate', closeSidebarEditorOnBlur);
        };

        editor.on('selectionUpdate', closeSidebarEditorOnBlur);
      },
    },
  ],
};
