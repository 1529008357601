import { TiptapEditorProvider } from '../../../../entities/tiptap-editor';
import { AIExtension } from '../../../../entities/tiptap-editor/extensions/AI';
import { DropHandleExtension } from '../../../../entities/tiptap-editor/extensions/DropHandle/DropHandleExtension';
import TriggerMenu from '../../../../entities/tiptap-editor/extensions/TriggerMenuExtension';
import { getMainEditorExtensions } from '../../../../entities/tiptap-editor/mainEditorExtensions';
import { snippetsModel } from '../../../../features/snippets';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { JSONContent } from '@tiptap/react';
import { debounce } from 'lodash';
import { Snippet } from '@distribute/shared/types';
import { SnippetEditorWrapper } from './SnippetEditorWrapper';
import { SnippetTiptapEditorPage } from './SnippetTiptapEditorPage';
import { getSlashMenuWithoutTasks } from './lib/helpers';

export const SnippetEditor = () => {
  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  ) as Snippet;

  const [data] = useState(currentSnippet?.content);

  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    dispatch(snippetsModel.actions.setIsSnippetSavedInEditor(true));
  }, [dispatch]);

  const handleChange = debounce((contentJson: JSONContent) => {
    dispatch(
      snippetsModel.actions.setCurrentSnippet({
        ...currentSnippet,
        content: contentJson,
      })
    );

    if (currentSnippet.isDraft) {
      dispatch(
        snippetsModel.actions.changeSnippetTextContent({
          snippetId: currentSnippet.id,
          content: contentJson,
          isDraft: currentSnippet.isDraft,
        })
      );
    } else {
      dispatch(snippetsModel.actions.setIsSnippetSavedInEditor(false));
    }
  }, 400);

  const SLASH_MENU_GROUPS_SNIPPET_EDITOR = getSlashMenuWithoutTasks();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(snippetsModel.actions.setCurrentSnippet(undefined));
    };
  }, [dispatch]);

  return (
    <TiptapEditorProvider
      options={{
        content: data,
        editorProps: {
          attributes: {
            class: 'main-editor',
          },
        },
        extensions: [
          ...getMainEditorExtensions().filter((ext) => ext.name !== 'snippet'),
          DropHandleExtension.configure({
            dispatch,
          }),
          AIExtension,
          TriggerMenu.configure({
            store,
            trigger: '/',
            groups: SLASH_MENU_GROUPS_SNIPPET_EDITOR,
          }),
        ],
        onUpdate: ({ editor, transaction }) => {
          if (transaction.getMeta('isPreventUpdate')) return;
          handleChange(editor.getJSON());
        },
      }}
    >
      <SnippetEditorWrapper
        currentSnippet={currentSnippet}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      >
        <SnippetTiptapEditorPage isSidebarOpen={isSidebarOpen} />
      </SnippetEditorWrapper>
    </TiptapEditorProvider>
  );
};
