type Node = { [key: string]: any };

export function findCustomTaskLists(data: Node | Node[]): Node[] {
  const taskLists = [];

  if (Array.isArray(data)) {
    for (const item of data) {
      taskLists.push(...findCustomTaskLists(item));
    }
  } else if (typeof data === 'object' && data !== null) {
    if (data.type === 'customTaskList') {
      taskLists.push(data);
    }
    if (Array.isArray(data.content)) {
      taskLists.push(...findCustomTaskLists(data.content));
    }
  }

  return taskLists;
}
