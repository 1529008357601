import { FC } from 'react';
import { EditorEvents, ReactNodeViewRenderer } from '@tiptap/react';
import {
  ContactCardExt,
  serializeContacts,
} from '@distribute/shared/generate-html';
import { ContactCardNodeView } from './ContactCard.renderer';
import { selectCurrentTeamWithError } from '../../../../features/teams/model/selectors';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { RootState } from '../../../../app';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../../../features/editor-sidebar';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    contactCard: {
      insertContactCard: (store: Store<RootState, AnyAction>) => ReturnType;
    };
  }
}

export const ContactCard = ContactCardExt.extend({
  addCommands() {
    return {
      insertContactCard:
        (store: Store<RootState, AnyAction>) =>
        ({ commands, editor }) => {
          const state = store.getState();
          const currentTeam = selectCurrentTeamWithError(state);

          const company = currentTeam.brandCompanyName;
          const name = currentTeam.currentMember.user?.displayName;
          const email = currentTeam.currentMember.user?.email;

          store.dispatch(editorSidebarModel.actions.setSidebarOpen(true));
          store.dispatch(
            editorSidebarModel.actions.setActiveConversionSettingsPanel(
              ActiveConversionSettingsPanel.CONTACT_CARD_EDITOR
            )
          );

          const closeSidebarEditorOnBlur = ({
            editor: ed,
          }: EditorEvents['selectionUpdate']) => {
            if (!ed.isActive('contactCard')) {
              store.dispatch(editorSidebarModel.actions.setSidebarOpen(false));
              store.dispatch(
                editorSidebarModel.actions.setActiveConversionSettingsPanel(
                  null
                )
              );
            }
            ed.off('selectionUpdate', closeSidebarEditorOnBlur);
          };

          editor?.on('selectionUpdate', closeSidebarEditorOnBlur);

          return commands.insertContent({
            type: 'contactCard',
            attrs: {
              company,
              description: `Greetings {recipient's name} 👋,\n\nI'm excited to begin our adventure for {project} here. This collaborative platform will act as our main headquarters, containing all the details related to our project for {company name}.\n\nBest,\n${name}`,
              isDescriptionBlockVisible: true,
              isPhotoBlockVisible: true,
              jobTitle: '',
              name,
              initialName: name,
              photoUrl: '',
              serializedContacts: serializeContacts([
                {
                  id: Date.now(),
                  type: 'email',
                  value: email ?? '',
                  order: 0,
                },
              ]),
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContactCardNodeView as FC);
  },
});
